<template>
  <v-card class="pa-4 summary-card">
    <v-row>
      <v-col cols="2"
        ><v-icon x-large :color="iconColor">{{ icon }}</v-icon></v-col
      >
      <v-col class="pt-5">{{ label }}</v-col>
    </v-row>
    <v-row>
      <v-col><v-textarea rows="15" :value="value" readonly filled no-resize></v-textarea></v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'DomainAddSummaryCard',
  props: ['icon', 'iconColor', 'label', 'value']
}
</script>

<style lang="sass">
.summary-card
  min-height: 530px
  max-height: 530px
</style>
