<template>
  <v-textarea v-bind="{ ...$props, ...$attrs }" v-on="$listeners" v-model="dataValue" counter :counter-value="counterValue" />
</template>

<script>
import ComponentWrapperMixin from './ComponentWrapperMixin.js'

export default {
  name: 'TextAreaLineCounting',
  mixins: [ComponentWrapperMixin],
  props: {
    value: {
      default: '',
      type: String
    },
    maxLines: {
      default: 1000,
      type: Number
    }
  },
  methods: {
    lineCount: function() {
      return this.dataValue.length ? this.dataValue.split(/\r\n|\r|\n/).length : 0
    },
    counterValue: function() {
      return this.lineCount() + '/' + this.$props['maxLines'].toString()
    }
  }
}
</script>
