<template>
  <div id="domain-add">
    <Headline />
    <DomainAdd />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import DomainAdd from '../../components/domain/DomainAdd'

export default {
  components: {
    Headline,
    DomainAdd
  }
}
</script>
