<template>
  <v-card class="pa-4">
    <v-row>
      <v-col cols="2"
        ><v-icon x-large :color="iconColor">{{ icon }}</v-icon></v-col
      >
      <v-col class="pt-5">{{ label }}</v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="result-list-wrapper d-flex flex-column">
          <div class="result-list white-bg has-review-btn">
            <v-checkbox
              class="select-all-domains"
              v-model="selectAllForOwnershipReview"
              :true-value="true"
              :false-value="false"
              hide-details
              color="primary"
              :ripple="false"
              :on-icon="'mdi-checkbox-marked'"
              :indeterminate="ownershipReviewDomains.length > 0 && selectAllForOwnershipReview === false"
              label="Select all domains"
            />

            <v-checkbox
              class="select-domain-for-ownership-review"
              :key="index"
              v-for="(domain, index) in value"
              v-model="ownershipReviewDomains"
              :label="domain"
              :value="domain"
              hide-details
              :multiple="true"
              :ripple="false"
            >
              <template #append>
                <ToolTip
                  v-if="startedOwnershipReviewProcess"
                  :description="getStateFor(domain).tooltip"
                  :icon="getStateFor(domain).icon"
                  placement="top"
                />
              </template>
            </v-checkbox>
          </div>
          <SecondaryButton
            class="review-button"
            @click="startOwnershipReview"
            :disabled="ownershipReviewDomains.length === 0 || startedOwnershipReviewProcess"
            >Review</SecondaryButton
          >
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SecondaryButton from '@/components/app/SecondaryButton'
import ToolTip from '@/components/others/ToolTip'

export default {
  components: { ToolTip, SecondaryButton },
  props: {
    icon: {
      String,
      required: true
    },
    iconColor: {
      String,
      required: true
    },
    label: {
      String,
      required: true
    },
    value: {
      Array,
      required: true
    },
    startedOwnershipForDomains: {
      Array,
      default: () => []
    }
  },
  data() {
    return {
      ownershipReviewDomains: [],
      selectAllForOwnershipReview: false,
      startedOwnershipReviewProcess: false
    }
  },
  watch: {
    selectAllForOwnershipReview() {
      if (this.selectAllForOwnershipReview) {
        this.ownershipReviewDomains = this.value
      } else {
        this.ownershipReviewDomains = []
      }
    }
  },
  methods: {
    startOwnershipReview() {
      this.startedOwnershipReviewProcess = true
      this.$emit('startOwnershipReview', { domains: this.ownershipReviewDomains })
    },
    getStateFor(domain) {
      if (!this.ownershipReviewDomains.includes(domain)) {
        return { icon: '', tooltip: '' }
      }

      if (this.startedOwnershipForDomains.includes(domain)) {
        return { icon: 'mdi-check', tooltip: 'Ownership review started' }
      }

      return { icon: 'mdi-progress-upload', tooltip: 'Ownership already review in progress' }
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

.result-list-wrapper
  .review-button
    margin-top: 15px

  .result-list
    background-color: #fff
    padding: 1rem
    border-radius: 4px
    border: 1px solid #CDCDCD
    overflow-y: auto
    min-height: 380px
    max-height: 380px

    .select-all-domains
      label
        font-weight: bold

    .v-input--selection-controls:first-child
      margin-top: 0

    .theme--light.v-label
      color: #000

    .theme--light.v-input--selection-controls:not(.v-input--indeterminate)
      .v-icon.mdi-check
        color: $success

    .theme--light.v-input--selection-controls:not(.v-input--indeterminate)
      .v-icon.mdi-progress-upload
        color: $warning
</style>
