<template>
  <div>
    <div v-if="state === 'add-form'">
      <v-form ref="form" class="domain-add-form" @submit.prevent="save()">
        <v-row>
          <v-col cols="12" lg="12">
            <Banner :text="$t('domain_add.banner_message')" type="info" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" order="2" order-lg="1">
            <TextAreaLineCounting
              :label="$t('domain_add.domains')"
              id="domains-add-field"
              filled
              height="300"
              :max-lines="1000"
              :rules="
                file === null
                  ? [
                      value => rules.required(value) || $t('domain_add.domains_required'),
                      value => rules.lessLinesThen(value, 1000) || $t('domain_add.to_many_line')
                    ]
                  : []
              "
              v-model="domains"
              no-resize
            />
          </v-col>
          <v-col cols="12" lg="6" order="1" order-lg="2" class="mt-2">
            <Headline2 :text="$t('domain_add.howto_heading')" />
            <p>{{ $t('domain_add.howto_part1') }}</p>
            <ul>
              <li>ns1.parkingcrew.net</li>
              <li>ns2.parkingcrew.net</li>
            </ul>
            <p>{{ $t('domain_add.howto_part2') }}</p>
          </v-col>
        </v-row>
        <v-row v-if="showFileInput">
          <v-col cols="12" lg="6">
            <v-file-input
              :label="$t('domain_add.file_input_label')"
              v-model="file"
              accept=".txt"
              filled
              :rules="[value => rules.onlyTextFiles(value) || $t('domain_add.file_input_error_message')]"
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col cols="12" lg="6">
            <p>
              <strong>{{ $t('domain_add.select_folder') }}</strong>
            </p>
            <FolderSelector :item-value="folderId" @update_folder="folderId = parseInt($event)" />
          </v-col>
        </v-row>
        <PrimaryButton id="add-domains-save" class="mt-4 add-domains-button" type="submit" :disabled="loading">
          <v-progress-circular v-if="loading" indeterminate size="20" color="white" class="mr-1"></v-progress-circular>
          {{ $t('domain_add.add') }}
        </PrimaryButton>
      </v-form>
    </div>

    <!-- This view can be removed when we switch to domain queue mode -->
    <div v-if="state === 'add-result'">
      <v-row>
        <v-col class="col-12">
          <Banner :text="result.message" :type="result.state" />
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col class="col-12 col-lg-3 col-sm-6">
          <DomainAddSummaryCard icon="mdi-check" icon-color="success" :value="result.added.join('\n')" :label="$t('domain_add.domains')" />
        </v-col>
        <v-col class="col-12 col-lg-3 col-sm-6">
          <DomainAddSummaryCard icon="mdi-information" icon-color="info" :value="result.existing.join('\n')" label="Already in account" />
        </v-col>
        <v-col class="col-12 col-lg-3 col-sm-6">
          <DomainOwnershipReviewCard
            v-if="result.conflicting.length > 0"
            icon="mdi-alert"
            icon-color="warning"
            :value="result.conflicting"
            @startOwnershipReview="onOwnershipReviewHandle"
            label="Ownership conflict"
            :started-ownership-for-domains="result.ownershipReviewStartedForDomains"
          />
          <DomainAddSummaryCard icon="mdi-alert" icon-color="warning" label="Ownership conflict" v-else />
        </v-col>
        <v-col class="col-12 col-lg-3 col-sm-6">
          <DomainAddSummaryCard icon="mdi-close-thick" icon-color="error" :value="result.invalid.join('\n')" label="Invalid domains" />
        </v-col>
      </v-row>
      <router-link :to="{ name: 'domains_index' }">
        <v-btn class="primary-button" rounded>{{ $t('domain_add.link_domain_list') }}</v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
import FolderSelector from '@/components/folder/FolderSelector'
import TextAreaLineCounting from '@/components/others/TextAreaLineCounting'
import DomainAddSummaryCard from '@/components/domain/DomainAddSummaryCard'
import Banner from '@/components/others/Banner'
import { rules } from '@/validation/rules'
import FileUploadHelper from '@/util/FileUploadHelper'
import Headline2 from '@/components/app/Headline2.vue'
import PrimaryButton from '@/components/app/PrimaryButton'
import DomainOwnershipReviewCard from '@/components/domain/DomainOwnershipReviewCard'

export default {
  components: {
    PrimaryButton,
    Headline2,
    Banner,
    TextAreaLineCounting,
    FolderSelector,
    DomainAddSummaryCard,
    DomainOwnershipReviewCard
  },
  props: {
    initialState: {
      String,
      default: 'add-form'
    }
  },
  mounted() {
    // Check if user has needed Permission
    this.$store.dispatch('user_permissions/fetchData').then(() => {
      this.showFileInput = this.$store.getters['user_permissions/hasPermission']('domain_uploads.create')
    })
  },
  data() {
    return {
      state: this.initialState,
      folderId: 1,
      domains: '',
      file: null,
      showFileInput: false,
      result: {
        state: 'success',
        added: [],
        existing: [],
        conflicting: [],
        invalid: [],
        ownershipReviewStartedForDomains: []
      },
      rules: { ...rules },
      loading: false
    }
  },
  methods: {
    save() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch('snackbar/showErrorMessage', {
          message: this.$t('domain_add.form_error')
        })
        return
      }

      this.loading = true

      if (this.file !== null) {
        this.uploadFile()
      } else {
        this.storeDomains()
      }
    },
    getFailedDomainArrayByState(responseData, state) {
      let matchingDomains = []
      responseData.failed.items.filter(data => data.state === state).forEach(item => matchingDomains.push(item.domain))
      return matchingDomains
    },
    storeDomains() {
      let data = { domains: this.domains, folder: this.folderId ?? 1 }
      return this.$store
        .dispatch('domain/store', data)
        .then(result => {
          this.loading = false
          this.state = 'add-result'
          let responseData = result.data ?? {}
          this.result.message = result.message ?? ''

          let successDomains = []
          responseData.success.items.forEach(item => successDomains.push(item.domain))
          this.result.added = successDomains

          if (!responseData.failed) {
            return
          }

          let alreadyInAccountDomains = this.getFailedDomainArrayByState(responseData, 'FAILED:ALREADY_IN_ACCOUNT')
          if (alreadyInAccountDomains.length > 0) {
            this.result.state = 'info'
          }
          this.result.existing = alreadyInAccountDomains

          let conflictingDomains = this.getFailedDomainArrayByState(responseData, 'FAILED:OWNERSHIP_CONFLICT')
          if (conflictingDomains.length > 0) {
            this.result.state = 'warning'
          }
          this.result.conflicting = conflictingDomains

          let invalidDomains = this.getFailedDomainArrayByState(responseData, 'FAILED:INVALID')
          if (invalidDomains.length > 0) {
            this.result.state = 'error'
          }
          this.result.invalid = invalidDomains
        })
        .catch(() => (this.loading = false))
    },
    onOwnershipReviewHandle(event) {
      let domains = event.domains.join('\n')
      let data = { domains: domains, folder: this.folderId ?? 1, review: 1 }
      this.result.message = ''

      return this.$store.dispatch('domain/store', data).then(result => {
        this.loading = false
        this.state = 'add-result'
        let responseData = result.data ?? {}
        this.result.message = result.message ?? ''

        responseData.ownership_review_started.items.forEach(item => this.result.ownershipReviewStartedForDomains.push(item.domain))
        this.result.state = 'info'
      })
    },
    uploadFile() {
      const fileUploadHelper = new FileUploadHelper()
      fileUploadHelper.addData('file', this.file)
      fileUploadHelper.addData('folder_id', this.folderId ?? 1)

      return this.$store.dispatch('new_domain_upload/store', fileUploadHelper.getUploadData()).then(() => {
        this.loading = false
        this.$store.dispatch('snackbar/showSuccessMessage', { message: this.$t('domain_add.file_upload_success_message') })
      })
    }
  }
}
</script>
